import React, { useState, useEffect } from "react";

import CircularProgress from "@material-ui/core/CircularProgress";

import "./NotFound.css";

const NotFound = () => {
  const [dogPic, setDogPic] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchDog = async () => {
      setIsLoading(true);
      fetch("https://dog.ceo/api/breed/retriever/golden/images/random")
        .then((res) => res.json())
        .then((data) => setDogPic(data.message));
      setIsLoading(false);
    };

    if (!dogPic) {
      fetchDog();
    }
  }, [dogPic]);

  return (
    <React.Fragment>
      <h2 className="yeet"> Oops! </h2>
      {isLoading ? <CircularProgress /> : <img alt={"dog"} src={dogPic} />}
      <p className="text">
        The page you are looking for,
        <code> {window.location.pathname} </code>
        does not exist...
      </p>
    </React.Fragment>
  );
};

export default NotFound;
