import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useParams, useLocation } from "react-router-dom";

import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

import "./ItemPage.css";

const ItemPage = (props) => {
  const params = useParams();
  const { pathname, hash, key } = useLocation();
  const [isLoading, setLoading] = useState(true);
  const [item, setItem] = useState(null);
  const [inCart, setInCart] = useState(false);
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState("");

  // fetch item details
  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === "") {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace("#", "");
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 300);
    }

    if (!item) {
      const fetchItem = async () => {
        setLoading(true);
        await fetch(`/api/item/${params.itemId}`)
          .then((res) => res.json())
          .then((data) => {
            if (data.item) {
              setItem(data.item);
              if (data.inCart) {
                setHelperText("This item is already in your cart");
                setInCart(true);
              } else {
                setInCart(false);
              }
            }
            if (data.error) {
              setError(true);
              setHelperText(data.error);
            }
          });
        setLoading(false);
      };
      fetchItem();
    }
  }, [item, params.itemId, pathname, hash, key]);

  // add item to the current order, creates one if it doesn't exist
  const addItemToOrder = async () =>
    await fetch(`/api/order/add_item/${item.id}`, {
      method: "POST",
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          setError(true);
          setHelperText(data.error);
        } else {
          setError(false);
          setHelperText(`${item.displayName} has been added to your order`);

          // modify item, so that it's seen as "inCart", disables button
          setInCart(true);
          props.incNumItems();
        }
      });

  return isLoading ? (
    <CircularProgress />
  ) : (
    <React.Fragment>
      {helperText && (
        <p className={error ? "error" : "helperText"}> {helperText} </p>
      )}
      <div className="crumbContainer">
        {item.parentGallery.crumbs.slice(-1).map((crumb) => (
          <React.Fragment key={crumb.id}>
            <div
              className={"oldCrumb"}
              onClick={() =>
                (window.location = `/gallery${
                  crumb.id === null ? "" : "/" + crumb.id
                }`)
              }
            >
              {crumb.name}
            </div>
            <KeyboardArrowDownIcon className="arrow" />
          </React.Fragment>
        ))}
      </div>
      <div className="imageHeader"> {item.displayName} </div>
      <div id="image-navigator" className="imageNavigator">
        <div className="arrowNav">
          {item.adjacentItems[0] && (
            <NavigateBeforeIcon
              className="arrowNavIcon"
              fontSize="inherit"
              onClick={() =>
                (window.location = `/item/${item.adjacentItems[0]}#image-navigator`)
              }
            />
          )}
        </div>
        <img
          alt={`Watermarked image ${item.displayName}`}
          className="image"
          key={item.id}
          src={item.watermarkUrl}
        />
        <div className="arrowNav">
          {item.adjacentItems[1] && (
            <NavigateNextIcon
              className="arrowNavIcon"
              fontSize="inherit"
              onClick={() =>
                (window.location = `/item/${item.adjacentItems[1]}#image-navigator`)
              }
            />
          )}
        </div>
      </div>
      <form>
        <Button
          color="primary"
          disabled={inCart}
          size="large"
          onClick={() => {
            addItemToOrder();
            window.scrollTo(0, 0);
          }}
          variant="contained"
        >
          Add to order
        </Button>
      </form>
    </React.Fragment>
  );
};

ItemPage.propTypes = {
  incNumItems: PropTypes.func,
};

export default ItemPage;
