import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CircularProgress from "@material-ui/core/CircularProgress";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

import "./Gallery.css";

const Gallery = () => {
  const { galleryId } = useParams();
  const [isLoading, setLoading] = useState(true);
  const [items, setItems] = useState([]);
  const [galleries, setGalleries] = useState([]);
  const [crumbs, setCrumbs] = useState([]);

  useEffect(() => {
    const fetchURL = "/api/gallery/" + (galleryId ? `?id=${galleryId}` : "");
    if (galleries.length === 0 && items.length === 0) {
      const fetchGallery = async () => {
        await setLoading(true);
        await fetch(fetchURL)
          .then((res) => res.json())
          .then((data) => {
            if (data.items) {
              setItems(
                data.items.sort(
                  (first, second) =>
                    parseInt(first.displayName) - parseInt(second.displayName)
                )
              );
            }
            if (data.galleries) {
              setGalleries(
                data.galleries.sort((first, second) =>
                  first.name.indexOf(".") > 0
                    ? // If the name includes a dot, parse int from dot
                      parseInt(first.name.substr(0, first.name.indexOf("."))) -
                      parseInt(second.name.substr(0, second.name.indexOf(".")))
                    : // Otherwise, use the names
                    first.name > second.name
                    ? 1
                    : -1
                )
              );
            }
            if (data.crumbs) {
              setCrumbs(data.crumbs);
            }
          });
        await setLoading(false);
      };

      fetchGallery();
    }
  }, [galleries.length, items.length]);

  return (
    <React.Fragment>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <div className="crumbContainer">
          {crumbs.slice(-2).map((crumb, order) => (
            <React.Fragment key={crumb.id}>
              {order + 1 === crumbs.length ? (
                <div className={"chiefCrumb"}>{crumb.name}</div>
              ) : (
                <div
                  className={"oldCrumb"}
                  onClick={() =>
                    (window.location = `/gallery${
                      crumb.id === null ? "" : "/" + crumb.id
                    }`)
                  }
                >
                  {crumb.name}
                </div>
              )}
              <div className="arrow">
                {order + 1 < crumbs.length && <KeyboardArrowDownIcon />}
              </div>
            </React.Fragment>
          ))}
        </div>
      )}
      {galleries && (
        <div className="galleryCardsContainer">
          {galleries.map((gallery, idx) => (
            <Card
              className="galleryCard"
              key={gallery.id}
              onClick={() => (window.location = `/gallery/${gallery.id}`)}
              raised={false}
              style={{ order: idx }}
            >
              <CardHeader
                titleTypographyProps={{ variant: "h3" }}
                title={gallery.name}
              />
              <CardContent>
                <div className="imageContainerXYZ">
                  <img
                    alt={`${gallery.keyItem.displayName}`}
                    className="scalableImage"
                    src={gallery.keyItem.thumbnailUrl}
                  />
                </div>
              </CardContent>
            </Card>
          ))}
        </div>
      )}
      <div className="thumbnailCardsContainer">
        {items &&
          items.map((item) => (
            <React.Fragment
              style={{ order: parseInt(item.displayName) }}
              key={item.id}
            >
              <div className="cardGrid">
                <div
                  className="imageContainerXYZ"
                  key={item.id}
                  onClick={() => (window.location = `/item/${item.id}`)}
                >
                  <img
                    alt={item.displayName}
                    className="scalableImage"
                    src={item.thumbnailUrl}
                  />
                </div>
                <h3 className="imageTitle">
                  {" "}
                  {item.displayName
                    .substring(item.displayName.indexOf("/"))
                    .substring(1)}{" "}
                </h3>
              </div>
            </React.Fragment>
          ))}
      </div>
    </React.Fragment>
  );
};

export default Gallery;
