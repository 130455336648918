import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";

import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import TextField from "@material-ui/core/TextField";

import "./LoginForm.css";

const LoginForm = () => {
  const [password, setPassword] = useState("");
  const [loginError, setLoginError] = useState("");
  const [loginDisabled, setLoginDisabled] = useState(true);

  useEffect(() => {
    setLoginDisabled(!password);
  }, [password]);

  const handleLogin = async (event) => {
    // prevent the page reload
    event.preventDefault();

    let payload = { password: password };

    // post the login attempt
    await fetch("/api/auth/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          setLoginError(data.error);
        } else {
          setLoginError("");
          window.location = "/gallery";
        }
      });
  };

  return (
    <React.Fragment>
      <h2> LOGIN </h2>
      <Card raised={true} className="loginCard">
        <CardContent className="header">
          <h3> Login to View Images </h3>
        </CardContent>
        <form autoComplete="off" className="authForm" onSubmit={handleLogin}>
          {loginError && <div className="errorMessage"> {loginError} </div>}
          <FormGroup onSubmit={handleLogin}>
            <TextField
              type="password"
              label={"gallery password"}
              margin="normal"
              onChange={(e) => setPassword(e.target.value)}
            />
            <FormGroup className="buttons" onSubmit={handleLogin} row>
              <Button
                color="primary"
                disabled={loginDisabled}
                size="large"
                type="submit"
                variant="contained"
              >
                Login
              </Button>
            </FormGroup>
          </FormGroup>
        </form>
      </Card>
    </React.Fragment>
  );
};

LoginForm.propTypes = {
  // not always set
  userId: PropTypes.string,
};

export default LoginForm;
