import React, { useEffect, useState } from "react";

import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import CircularProgress from "@material-ui/core/CircularProgress";

import "./Order.css";

// Every order has an ID.
// This ID is used to retrieve the relevant items
const OrderForm = () => {
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [items, setItems] = useState([]);
  const [total, setTotal] = useState(null);

  useEffect(() => {
    // get items for the order
    if (!items.length) {
      const fetchOrder = async () => {
        setIsLoading(true);
        await fetch("/api/order/")
          .then((res) => res.json())
          .then((data) => {
            if (data.items) {
              setItems(data.items);
              setTotal(data.totalValue);
              if (data.items.length === 0) {
                setHelperText("There are no items in your cart");
              }
            } else {
              setError(true);
              if (data.error) {
                setHelperText(data.error);
              } else {
                setHelperText("Something went wrong...");
              }
            }
          });
        setIsLoading(false);
      };

      fetchOrder();
    }
  }, [items.length]);

  const handleRemoveItem = (item) => {
    // remove item from an order, refresh item list
    fetch(`/api/order/remove_item/${item.id}`, { method: "POST" })
      .then((res) => res.json())
      .then(() => setItems([]));
    setHelperText(`Removed ${item.displayName}`);
  };

  return (
    <React.Fragment>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <React.Fragment>
          <h2> Review your order </h2>
          {helperText && (
            <p className={error ? "error" : "helper"}>{helperText}</p>
          )}
          {Boolean(items.length) && (
            <React.Fragment>
              <p className="totalPrice">
                Total Price: ${Number.parseFloat(total).toFixed(2)}
              </p>
              <Button
                color="primary"
                disabled={!items.length}
                onClick={() => (window.location = "/checkout")}
                size="small"
                type="submit"
                variant="contained"
              >
                Checkout
              </Button>
            </React.Fragment>
          )}
          <div className="imageCardContainer">
            {items.map((item) => (
              <React.Fragment key={item.id}>
                <Card className="imageCard" raised="true">
                  <CardHeader
                    className="imageCardContent"
                    title={item.displayName}
                  />
                  <CardContent className="imageCardImageContainer">
                    <img
                      alt={item.displayName}
                      className={"imageClass"}
                      onClick={() => (window.location = `/item/${item.id}`)}
                      src={item.thumbnailUrl}
                    />
                  </CardContent>
                  <CardContent className="imageCardContent">
                    <p className="pricing">
                      {item.actualPrice !== item.basePrice ? (
                        <React.Fragment>
                          <s>
                            Price: $
                            {Number.parseFloat(item.basePrice).toFixed(2)}
                          </s>{" "}
                          <br />
                          Discount Price: $
                          {Number.parseFloat(item.actualPrice).toFixed(2)}
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          Price: $
                          {Number.parseFloat(item.actualPrice).toFixed(2)}
                        </React.Fragment>
                      )}
                    </p>
                  </CardContent>
                  <CardContent>
                    <Button
                      color="secondary"
                      onClick={() => handleRemoveItem(item)}
                      size="small"
                      variant="contained"
                    >
                      Remove
                    </Button>
                  </CardContent>
                </Card>
              </React.Fragment>
            ))}
          </div>
          <p className="totalPrice">
            Total Price: ${Number.parseFloat(total).toFixed(2)}
          </p>
          <Button
            color="primary"
            m={16}
            disabled={!items.length}
            onClick={() => (window.location = "/checkout")}
            size="small"
            type="submit"
            variant="contained"
          >
            Checkout
          </Button>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default OrderForm;
